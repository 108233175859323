import React, { useState } from 'react'

import { StreamSelectionControl } from '../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroom'

import { useWatermark } from './hooks/useWatermark'
import { Button } from '@material-ui/core'

interface WatermarkProps {
  stream: StreamSelectionControl
  watermarkHidden: boolean
}

export const Watermark = (props: WatermarkProps) => {
  useWatermark({ ...props })

  return (
    <></>
  )
}
